import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Chart from "react-apexcharts";
export default function RawDataGraph({
    meterRawData,
    meterInfo,
    isSuccessMeterRawData,
    isErrorMeterRawData,
    errorMessageMeterRawData,
    isLoadingMeterRawData,
    startDate,
    endDate,
}) {
    const handleDownloadCSV = () => {
        let csvContent = "";

        meterRawData?.values.forEach((dataPoint) => {
            const dateObj = new Date(dataPoint.x);
            const hours = dateObj.getHours().toString().padStart(2, "0");
            const minutes = dateObj.getMinutes().toString().padStart(2, "0");
            const day = dateObj.getDate().toString().padStart(2, "0");
            const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
            const year = dateObj.getFullYear().toString();

            csvContent += `${year}-${month}-${day} ${hours}:${minutes},${dataPoint.y}\n`;
        });

        if (csvContent !== "") {
            const anchorEle = document.createElement("a");
            anchorEle.href = `data:text/csv;charset=utf-8,${encodeURI(
                csvContent
            )}`;
            anchorEle.target = "_blank";
            anchorEle.download = `${meterInfo.serial_number}-${meterRawData?.register}-${startDate.format("YYYY-MM-DD")}to${endDate.format("YYYY-MM-DD")}.csv`;
            anchorEle.click();
        }
    };

    if (isLoadingMeterRawData) {
        return (
            <Card variant="outlined" style={{ marginTop: "1em" }}>
                <Grid
                    container
                    style={{
                        maxWidth: "1700px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <Grid item xs={12} align="right">
                        <div>Loading....</div>
                    </Grid>
                </Grid>
            </Card>
        );
    } else if (isErrorMeterRawData) {
        return (
            <Card variant="outlined" style={{ marginTop: "1em" }}>
                <Grid
                    container
                    style={{
                        maxWidth: "1700px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <Grid item xs={12} align="right">
                        <div>
                            Error:{" "}
                            {errorMessageMeterRawData?.data?.error_message}
                        </div>
                    </Grid>
                </Grid>
            </Card>
        );
    } else if (isSuccessMeterRawData) {
        const graphUnits =
            meterRawData.register === "ActivePower"
                ? "W"
                : meterRawData.register === "TotalActiveEnergy"
                ? "kWh"
                : "???";

        const options = {
            responsive: [
                {
                    breakpoint: 600,
                    options: {
                        xaxis: {
                            tickAmount: 8,
                        },
                    },
                },
                {
                    breakpoint: 1200,
                    options: {
                        xaxis: {
                            tickAmount: 12,
                        },
                    },
                },
            ],
            noData: {
                text: "NO DATA FOUND",
                align: "center",
                verticalAlign: "middle",
            },
            chart: {
                animations: {
                    enabled: false,
                    dynamicAnimation: {
                        enabled: false,
                    },
                },
                toolbar: {
                    tools: {
                        download: false,
                    },
                },
            },

            stroke: {
                curve: "stepline",
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true,
                    format: "dd MMM HH:mm:ss",
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                labels: {
                    datetimeUTC: false,
                },
                title: {
                    text: "Date Time",
                },
            },
            yaxis: {
                decimalsInFloat: 0,
                title: {
                    text: graphUnits,
                },
            },
            title: {
                text: `${meterInfo?.serial_number} ${meterRawData?.register}`,
                align: "left",
            },
           };

        const series = [
            {
                name: "Value",
                data: meterRawData?.values,
                type: "line",
            },
            {
                name: "Broker-Online-Offline",
                data: meterRawData?.broker_on_off_data,
                type: "area",
                color: "red",
            },
        ];

        return (
            <Card variant="outlined" style={{ marginTop: "1em" }}>
                <Grid
                    container
                    style={{
                        maxWidth: "1700px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <Grid item xs={12} align="right">
                        <Button onClick={handleDownloadCSV}>
                            Download CSV
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Chart options={options} series={series} height={450} />
                    </Grid>
                </Grid>
            </Card>
        );
    } else {
        return <></>;
    }
}
