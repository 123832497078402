//React
import * as React from "react";
//Material UI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

//Redux
import { useResetGeysersMutation } from "./operatorDashboardAPISlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import TuneIcon from "@mui/icons-material/Tune";
import {
  showSuccess,
  showError,
} from "../../Global/notifications/notificationsSlice";
import { useDispatch } from "react-redux";

export default function ResetGeysers() {
  const dispatch = useDispatch();

  const [
    resetGeysers,
    { data: resetData, isSuccess: isResetSuccess, isError: isResetError },
  ] = useResetGeysersMutation();

  const [open, setopen] = React.useState(false);
  const [openCloseDialog, setopenCloseDialog] = React.useState(false);

  const handleOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  React.useEffect(() => {
    if (isResetSuccess) {
      setopenCloseDialog(false);
      console.log(resetData);
      dispatch(showSuccess(resetData.success));
    }
  }, [isResetSuccess]);

  React.useEffect(() => {
    if (isResetError) {
      setopenCloseDialog(false);
      dispatch(showError(resetData.error));
    }
  }, [isResetError]);

  const resetGeysersButton = (event) => {
    const body = {};
    const request = {
      body: body,
    };

    resetGeysers(request);
  };

  return (
    <>
      <Button
        sx={{
          color: "#ED6C02",
          borderColor: "#ED6C02",
          borderRadius: "8px",
          border: "1px solid",
          fontWeight: "bold",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#ED6C0280",
            color: "#ED6C02",
            borderColor: "#ED6C02",
          },
        }}
        style={{ display: "flex", justifyContent: "flex-end", height: "40%" }}
        variant="outlined"
        startIcon={<WarningIcon sx={{ color: "#ED6C02" }} />}
        onClick={handleOpen}
      >
        EMERGENCY INTERVENTION
      </Button>
      <ConfirmResetDialog
        openCloseDialog={openCloseDialog}
        setopenCloseDialog={setopenCloseDialog}
        resetGeysersButton={resetGeysersButton}
      />
      <div>
        <Dialog
          BackdropProps={{ invisible: true }}
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClose} // Corrected line
        >
          <DialogTitle>Reset Geysers</DialogTitle>
          <DialogContent>
            <Grid container sx={{ mt: 2 }}>
              <Grid item align="left" xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => setopenCloseDialog(true)}
                  sx={{ mr: 2 }}
                >
                  Reset Geysers
                </Button>
              </Grid>
              <Grid item align="right" xs={6}>
                <Button
                  variant="outlined"
                  onClick={handleClose} // Corrected line
                  sx={{ mr: 2 }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

function ConfirmResetDialog({
  setopenCloseDialog,
  openCloseDialog,
  resetGeysersButton,
}) {
  return (
    <>
      <div>
        <Dialog
          BackdropProps={{ invisible: true }}
          fullWidth
          maxWidth="md"
          open={openCloseDialog}
          onClose={() => setopenCloseDialog(false)}
        >
          <DialogTitle>
            This will reset all the geysers and send Relay Connect Messages to
            all Geysers that are Scheduled to be on.
          </DialogTitle>
          <DialogContent>
            <Typography>Do you want to proceed?</Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item align="right" xs={9} />
              <Grid item align="right" xs={1}>
                <Button
                  variant="text"
                  onClick={() => setopenCloseDialog(false)}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item align="right" xs={2}>
                <Button
                  variant="contained"
                  onClick={resetGeysersButton}
                  sx={{ mr: 2 }}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

