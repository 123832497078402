import styled from 'styled-components';

export const Nav = styled.nav`
  background: #2E2E2E;
  height: 60px;
  width: 100%;
  padding: 5;
  position: fixed;
  top: 0;
  z-index: 1000;
`;


