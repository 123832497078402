//React
import * as React from "react";
import { useState, useEffect } from "react";
//Material UI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AlertTitle from "@mui/material/AlertTitle";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
//Redux
import {
  useGetTOUListQuery,
  useAddTOUMutation,
  useUpdateBulkTOUMutation,
  useDeleteTOUMutation,
} from "../api/financialApiSlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import SettingsIcon from '@mui/icons-material/Settings';

export default function TOU() {
  const {
    data: tou_data,
    isSuccess: isTOUSuccess,
    refetch,
  } = useGetTOUListQuery();
  const [
    addTOU,
    { data: addData, isSuccess: isAddSuccess, isError: isAddError },
  ] = useAddTOUMutation();

  const [open, setopen] = React.useState(false);
  const [saved, setSaved] = React.useState("Saved!");
  const [bulkArray, setBulkArray] = useState([]);
  const [openCloseDialog, setopenCloseDialog] = React.useState(false);

  const handleOpenCloseDialog = () => {
    setopenCloseDialog(true);
  };
  React.useEffect(() => {
    if (isTOUSuccess) {
      setBulkArray(tou_data);
    }
  }, [isTOUSuccess, tou_data]);

  React.useEffect(() => {
    if (isAddSuccess) {
      refetch();
    }
  }, [refetch, isAddSuccess]);

  const handleOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  const addTOUInstance = (event) => {
    const body = {
      period: "Peak",
      start_date: "",
      end_date: "",
      start: "",
      end: "",
      day: "Weekday",
    };
    const request = {
      body: body,
    };

    addTOU(request);
  };

  const [
    updateBulkTOU,
    { isSuccess: isUpdateSuccess, isError: isUpdateError },
  ] = useUpdateBulkTOUMutation();

  const handleBulkUpdate = () => {
    const request = {
      body: bulkArray,
    };
    updateBulkTOU(request);
  };
  React.useEffect(() => {
    if (isUpdateSuccess) {
      setSaved("Saved!");
    } else if (isUpdateError) {
      setSaved("Save");
    } else {
      setSaved("Save");
    }
  }, [isUpdateSuccess]);

  if (isTOUSuccess) {
    return (
      <>
        <ListItem sx={{ marginTop: 2 }} button onClick={handleOpen}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="TOU" />
        </ListItem>
        <ConfirmCloseDialog
          openCloseDialog={openCloseDialog}
          setopenCloseDialog={setopenCloseDialog}
          open={open}
          setopen={setopen}
        />
        <div>
          <Dialog
            BackdropProps={{ invisible: true }}
            fullWidth
            maxWidth="xl"
            open={open}
            onClose={() => setopen(false)}
          >
            <DialogTitle>TOU</DialogTitle>
            <DialogContent>
              <Grid container>
                {tou_data?.map((tou, index) => (
                  <Grid key={tou.id} container>
                    <TOUComponent
                      tou={tou}
                      index={index}
                      setSaved={setSaved}
                      setBulkArray={setBulkArray}
                      bulkArray={bulkArray}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item align="left" xs={6}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addTOUInstance}
                    sx={{ mr: 2 }}
                  >
                    Add new
                  </Button>
                </Grid>

                <Grid item align="right" xs={4} />
                <Grid item align="right" xs={1}>
                  <Button
                    variant="text"
                    onClick={
                      saved == "Save" ? handleOpenCloseDialog : handleClose
                    }
                    sx={{ mr: 2 }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item align="right" xs={1}>
                  <Button
                    variant="outlined"
                    startIcon={<SaveIcon />}
                    disabled={saved == "Save" ? false : true}
                    onClick={handleBulkUpdate}
                    sx={{ mr: 2 }}
                  >
                    {saved}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function TOUComponent({ tou, index, setSaved, setBulkArray, bulkArray }) {
  const [deleteTOU] = useDeleteTOUMutation();
  const saTimeOffset = 2 * 60 * 60 * 1000;

  const [period, setperiod] = useState(tou.period);
  const [startDate, setstart_date] = useState(dayjs(tou.start_date));
  const [endDate, setend_date] = useState(dayjs(tou.end_date));
  const [startTime, setstart] = React.useState(dayjs("2023-01-01T" + tou.start));
  const [endTime, setend] = React.useState(dayjs("2023-01-01T" + tou.end));
  const [day, setday] = useState(tou.day);

  const [currently_deleting_index, setCurrentlyDeletingIndex] = useState("");
  const period_choices = ["Peak", "Standard", "OffPeak"];
  const day_choices = ["Weekday", "Saturday", "Sunday"];
  const [dataArray, setDataArray] = useState({
    id: tou.id,
    start_date: startDate,
    end_date: endDate,
    start: startTime,
    end: endTime,
  });

  const handleDeleteButton = (index) => {
    setCurrentlyDeletingIndex(index);
  };

  const handleCancelDelete = () => {
    setCurrentlyDeletingIndex("");
  };

  const handleConfirmDelete = (tou_id) => {
    deleteTOU({ tou_id });
    setCurrentlyDeletingIndex("");
  };

  const setPeriod = (period) => {
    setperiod(period);
    setDataArray({ ...dataArray, period: period });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === tou.id) {
          return {
            ...item,
            period: period,
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const onChangeStartDate = (date: Dayjs) => {
    const startOfDay = date.set("hour", 0).set("minute", 0).set("second", 0);

    setstart_date(startOfDay);

    setDataArray({
      ...dataArray,
      start_date: startOfDay.format("YYYY-MM-DD HH:mm:ss"),
    });

    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === tou.id) {
          return {
            ...item,
            start_date: startOfDay.format("YYYY-MM-DD HH:mm:ss"),
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const onChangeEndDate = (date: Dayjs) => {
    const endOfDay = date.set("hour", 21).set("minute", 59).set("second", 59);

    setend_date(endOfDay);

    setDataArray({
      ...dataArray,
      end_date: endOfDay.format("YYYY-MM-DD HH:mm:ss"),
    });

    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === tou.id) {
          return {
            ...item,
            end_date: endOfDay.format("YYYY-MM-DD HH:mm:ss"),
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const onChangeStart = (time: Dayjs) => {
    const saTimeOffset = 2 * 60 * 60 * 1000;
    const utcStartTime = time.subtract(
      saTimeOffset / (60 * 60 * 1000),
      "hours"
    );
    setstart(utcStartTime);
    setDataArray({
      ...dataArray,
      start:
        utcStartTime.$H + ":" + utcStartTime.$m + ":" + utcStartTime.$s,
    });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === tou.id) {
          return {
            ...item,
            start:
              utcStartTime.$H + ":" + utcStartTime.$m + ":" + utcStartTime.$s,
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const onChangeEnd = (time: Dayjs) => {
    const saTimeOffset = 2 * 60 * 60 * 1000;
    const utcEndTime = time.subtract(saTimeOffset / (60 * 60 * 1000), "hours");
    setend(utcEndTime);

    setDataArray({
      ...dataArray,
      end: utcEndTime.$H + ":" + utcEndTime.$m + ":" + utcEndTime.$s,
    });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === tou.id) {
          return {
            ...item,
            end: utcEndTime.$H + ":" + utcEndTime.$m + ":" + utcEndTime.$s,
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const setDay = (day) => {
    setday(day);
    setDataArray({ ...dataArray, day: day });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === tou.id) {
          return {
            ...item,
            day: day,
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  return (
    <>
      <Grid xs={11}>
        <Grid container sx={{ p: 1 }} gap={1}>
        <Grid item xs={1.5}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="test-select-label">Period</InputLabel>
              <Select
                labelId="type-select-label"
                label="Period"
                id="period"
                name="period"
                value={period}
                required
                onChange={(e) => setPeriod(e.target.value)}
              >
                {period_choices.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1.5} sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-cn">
              <MobileDatePicker
                label="Start Date"
                format="YYYY/MM/DD"
                value={startDate}
                onChange={onChangeStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1.5} sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-cn">
              <MobileDatePicker
                label="End Date"
                format="YYYY/MM/DD"
                value={endDate}
                onChange={onChangeEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} required>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  value={startTime.add(saTimeOffset, "ms")}
                  required
                  label="Start Time"
                  onChange={onChangeStart}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} required>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  value={endTime.add(saTimeOffset, "ms")}
                  required
                  label="End Time"
                  onChange={onChangeEnd}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1.5}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="test-select-label">Day</InputLabel>
              <Select
                labelId="type-select-label"
                label="Day"
                id="day"
                name="day"
                value={day}
                required
                onChange={(e) => setDay(e.target.value)}
              >
                {day_choices.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={1}>
        <IconButton
          color="error"
          size="large"
          onClick={() => handleDeleteButton(index)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>

      <Grid xs={2} />
      <Grid xs={8}>
        <Collapse in={index === currently_deleting_index}>
          <Alert severity="error">
            <AlertTitle>Caution!</AlertTitle>
            Are you sure you would like to <strong>DELETE</strong> this entry ?
            <strong>This action can not be undone</strong>
          </Alert>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={handleCancelDelete}
          >
            No Cancel
          </Button>
          <Button
            // style={theme.updateButtonStyle.root}
            variant="contained"
            size="small"
            color="success"
            sx={{ ml: 1 }}
            onClick={() => handleConfirmDelete(tou.id)}
          >
            Yes I am sure
          </Button>
        </Collapse>
      </Grid>
      <Grid xs={2} />
    </>
  );
}

function ConfirmCloseDialog({
  setopenCloseDialog,
  openCloseDialog,
  setopen,
  open,
}) {
  const handleCloseAll = () => {
    setopen(false);
    setopenCloseDialog(false);
  };
  return (
    <>
      <div>
        <Dialog
          BackdropProps={{ invisible: true }}
          fullWidth
          maxWidth="md"
          open={openCloseDialog}
          onClose={() => setopenCloseDialog(false)}
        >
          <DialogTitle>
            Are you sure you want to close without saving changes?
          </DialogTitle>
          <DialogContent>
            <Typography>Your changes will be lost</Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item align="right" xs={9} />
              <Grid item align="right" xs={1}>
                <Button
                  variant="text"
                  onClick={() => setopenCloseDialog(false)}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item align="right" xs={2}>
                <Button
                  variant="contained"
                  onClick={handleCloseAll}
                  sx={{ mr: 2 }}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
