import React from "react";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useState } from "react";

export default function GraphLabels() {
  return (
    <Grid container spacing={2} alignItems="center" sx={{ p: 2 }}>
 
      <Labels/>
    
    </Grid>
  );
}

function Labels() {
  const [openBaseline, setOpenBaseline] = useState(false);
  const [openAssessment, setAssessment] = useState(false);

  const handleTooltipClose = (tooltipType) => {
    if (tooltipType === "Baseline") {
      setOpenBaseline(false);
    } else if (tooltipType === "Assessment") {
      setAssessment(false);
    }
  };

  const handleTooltipOpen = (tooltipType) => {
    if (tooltipType === "Baseline") {
      setOpenBaseline(true);
    } else if (tooltipType === "Assessment") {
      setAssessment(true);
    }
  };

  const textFieldStyle = {
    // minWidth: "346px",
    // maxWidth: "600px",
    height: "68px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    padding: 10,
    cursor: "pointer",
    transition: "cursor 0.3s ease",

  };

  const renderCircle = (color) => (
    <div
      style={{
        width: "18px",
        height: "4px",
        borderRadius: "100%",
        backgroundColor: color,
        marginRight: "8px",
      }}
    />
  );

  return (
    <Grid container alignItems="center" spacing={1} sx={{ p: 2 }}>
      {/* <Grid item sx={0.5} align="left"/> */}
    <Grid item xs={2} align="center">
      <ClickAwayListener
        onClickAway={() => handleTooltipClose("Baseline")}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => handleTooltipClose("Baseline")}
          open={openBaseline}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Normalized Demand of the Baseline Sample Geyers"
          placement='bottom-start'
          arrow={true}
        >
          <InputBase
            readOnly
            onClick={() => handleTooltipOpen("Baseline")}
            startAdornment={renderCircle("#94ADFF")}
            value="Normalized Baseline (MVA)"
            style={textFieldStyle}
          />
        </Tooltip>
      </ClickAwayListener>
    </Grid>
    <Grid item xs={1.5} align="center">
      <ClickAwayListener
        onClickAway={() => handleTooltipClose("Assessment")}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => handleTooltipClose("Assessment")}
          open={openAssessment}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Demand of Assessment Geysers"
          placement='bottom-start'
          arrow={true}
        >
          <InputBase
            onClick={() => handleTooltipOpen("Assessment")}
            readOnly
            startAdornment={renderCircle("#FDB940")}
            value="Assessment (MVA)"
            style={textFieldStyle}
          />
        </Tooltip>
      </ClickAwayListener>
    </Grid>
  </Grid>
);
 
}

