import { createSlice } from "@reduxjs/toolkit/";

const initialState = {

  logo:true,
  navHeading:'Tjommie QE App',
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  
  reducers: {
    
    setlogo:(state, action) =>{
      state.logo = action.payload;
    },
   setnavHeading:(state, action) =>{
    state.navHeading = action.payload;
  },
  },
});


export const getlogo = (state)=> state.nav.logo;
export const getnavHeading = (state)=> state.nav.navHeading;

export const { setlogo,setnavHeading } = navSlice.actions;

export default navSlice.reducer;
