import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Grid } from "@mui/material";
export default function InstallationAssistance() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClickOpen}
        sx={{
          color: "#FDB940",
          "&:hover": {
            backgroundColor: "rgba(253, 185, 64, 0.1)",
          },
        }}
      >
        <HelpOutlineIcon />
      </IconButton>

      {/* Dialog */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { maxWidth: "95%",borderRadius: "12px", maxHeight: "95vh" },

        }}
      >
        <DialogContent>
          <Grid container alignITems="left">
            <Grid item sx={{ml:0.5}} xs={2}>
        <HelpOutlineIcon
              sx={{
                color: "#FDB940",
              }}
              fontSize="large"
            />
            </Grid>
            <Grid item xs={9.5}>
          <Typography variant="h6" gutterBottom>
           
            Tjommie Installation Assistance
          </Typography>
          </Grid>
          </Grid>
          <Box sx={{ overflowY: "auto", marginTop: 2 }}>
            <Typography variant="body2" sx={{ml:0.5}}>
              <b> Note: </b>
             
                  The App will resume any in-progress job cards on login. Continue with an in-progress installation, ensuring the correct device is being used. Alternatively, you can abort the job card to start a new installation.
               
              <List>
               
                <ListItem>
                  It is essential to run the app in real-time during installs.
                </ListItem>
                <ListItem>
                  Use only the stock allocated to your team. Scanning another team's stock will not work with the app. Installs that do not follow the correct process will fail.
                </ListItem>
                <ListItem>
                  Do not use another installer's credentials to log in.
                </ListItem>
                <ListItem>
                  Do not log in on multiple devices using the same credentials. An installer's app can only be used for one install at a time.
                </ListItem>
                <ListItem>
                  Ensure you have tested the Tjommie Installer App thoroughly in a test environment before proceeding with customer installs.
                </ListItem>
                <ListItem>
                  If you have any persisting issues and are unable to continue working, contact Neura for assistance.
                </ListItem>
                <ListItem>
                  Ensure that your internet connection is connected to 3G or above. Slow connection will create errors when uploading data.
                </ListItem>
                <ListItem>
                  Ensure you have enough battery life and mobile data at all times to complete installations. A minimum of 100 MB of data is recommended.
                </ListItem>
                <ListItem>
                  <b>General troubleshooting:</b>
                </ListItem>
                <ListItem>
                  A: Abort the current installation, log out, close the browser tab and browser, log in, and start the install again. If the error persists, follow this process again and start a new install with a new device and QR code.
                </ListItem>
                <ListItem>
                  B: If the device is connected (blue light flashing) but the app says not connected, check that the light is blinking and press "test connection" or refresh the page.
                </ListItem>
                <ListItem>
                  C: Switch off the mains for 1 minute, switch it back on, wait for the blue light to flash (indicating the device is connected), and refresh the device details page on the app.
                </ListItem>
                <ListItem>
                  D: Close the web page completely, and then reopen it. The App will resume any in-progress job cards on login and continue with an in-progress installation, ensuring the correct device is being used.
                </ListItem>
                <ListItem>
                  E: Abort the job by clicking the red X at the top left of the screen. When prompted, confirm the abort and start the installation over.
                </ListItem>
              </List>
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              backgroundColor: "#FDB940",
              color: "white",
              "&:hover": {
                backgroundColor: "#FDB940",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};