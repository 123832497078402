import * as React from "react";
import Box from "@mui/material/Box";
import { ReactComponent as SvgFile } from "./logo.svg";
import { useState } from "react";
import Stepper from "./Stepper";
import { Nav } from "./Elements";
import { Grid } from "@mui/material";
import { getsteps, getCurrentStep } from "./stepperSlice";
import { useSelector } from "react-redux";
import CancelJobButton from "../CancelJobButton";
import InstallationAssistance from "../InstallationAssistance";

import "@fontsource/roboto/400.css";

export default function NavbarStepper({ step }) {
  const stepsGlobal = useSelector(getCurrentStep);
  const [currentStep, setCurrentStep] = useState(step ? step : stepsGlobal);
  const steps = useSelector(getsteps);

  React.useEffect(() => {
    if (step) setCurrentStep(step);
  }, [stepsGlobal, step, currentStep]);

  const circleStyle = {
    width: "24px",
    height: "22px",
    position: "absolute",
    left: "35%",
    borderRadius: "50%",
    backgroundColor: "#FDB940",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    fontSize: "16px",
    fontFamily: ["Roboto"],
  };

  const headingStyle = {
    position: "absolute",
    left: "45%",
    alignItems: "center",
    justifyContent: "center",
    color: "#FDB940",
    fontSize: "16px",
    fontFamily: ["Roboto"],
  };

  return (
    <Box sx={{ mb: 15, background: "#FDB940" }}>
      <Nav>
        <br />
        <Grid container alignItems="center" spacing={0.5} sx={{ mt: -2 }}>
          <Grid item xs={2} align="center">
            <CancelJobButton />
          </Grid>
          <Grid item xs={8} align="center">
            <div style={circleStyle}>{currentStep + 1}</div>
            <div style={headingStyle}>
              <StepperHeading step={currentStep} />
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
                   <InstallationAssistance/>

          </Grid>
          <Grid item xs={12} align="center" sx={{ ml: 1, mt: 0.5 }}>
            <Stepper steps={steps} currentStep={currentStep} />
          </Grid>
          {/* New Grid item for the SVG */}
        </Grid>
      </Nav>
    </Box>
  );
}

function StepperHeading({ step }) {
  switch (step) {
    case 0:
      return "Details";
    case 1:
      return "Scan QR";
    case 2:
      return "Work Area Picture";
    case 3:
      return "Take Pictures";
    case 4:
      return "Take Pictures";
    case 5:
      return "Install";
    case 6:
      return "Test Connection";
    case 7:
      return "Take Pictures";
    case 8:
      return "Take Pictures";
    case 9:
      return "Work Area Picture";
    default:
      return "";
  }
}
