import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
const scheduleAdapter = createEntityAdapter();
const initialState = scheduleAdapter.getInitialState();

export const financialApiSlice = apiSlice.injectEndpoints({

  endpoints: (builder) => ({    
    //GET REQUESTS -------------------------------------------------------------
    getPublicHolidayList: builder.query({
        query: () => "/dsm/public-holidays/",
        providesTags: ["public_holiday"],
      }),

    getTOUList: builder.query({
        query: () => "/dsm/tou-data/",
        providesTags: ["tou"],
      }),
      
    getTariffList: builder.query({
        query: () => "/dsm/tariff-data/",
        providesTags: ["tariff"],
      }),      

    //POST REQUESTS -------------------------------------------------------------
    addPublicHoliday: builder.mutation({
        query: (request) => ({
        url: `/dsm/public-holidays/`,
        method: "POST",
        body: request.body,
        }),
        invalidatesTags: ["public_holiday"],
      }),
    addTOU: builder.mutation({
        query: (request) => ({
        url: `/dsm/tou-data/`,
        method: "POST",
        body: request.body,
        }),
        invalidatesTags: ["tou"],
        }),
    addTariff: builder.mutation({
        query: (request) => ({
        url: `/dsm/tariff-data/`,
        method: "POST",
        body: request.body,
        }),
        invalidatesTags: ["tariff"],
        }),            
                     
    //PATCH REQUESTS -------------------------------------------------------------
    updateBulkPublicHoliday: builder.mutation({
        query: (request) => ({
        url: `/dsm/public-holidays/bulk-update/`,
        method: "PATCH",
        body: request.body,
        }),
        invalidatesTags: ["public_holiday"],
      }),
    updateBulkTOU: builder.mutation({
        query: (request) => ({
        url: `/dsm/tou-data/bulk-update/`,
        method: "PATCH",
        body: request.body,
        }),
        invalidatesTags: ["tou"],
        }),
    updateBulkTariff: builder.mutation({
        query: (request) => ({
        url: `/dsm/tariff-data/bulk-update/`,
        method: "PATCH",
        body: request.body,
        }),
        invalidatesTags: ["tariff"],
        }),            

    //DELETE REQUESTS -------------------------------------------------------------
    deletePublicHoliday: builder.mutation({
        query: ({holiday_id}) => ({
            url: `/dsm/public-holidays/delete/${holiday_id}/`,
            method: "DELETE",
            }),
            invalidatesTags: ["public_holiday"],
            }),
    deleteTOU: builder.mutation({
        query: ({tou_id}) => ({
            url: `/dsm/tou-data/delete/${tou_id}/`,
            method: "DELETE",
            }),
            invalidatesTags: ["tou"],
            }),   
    deleteTariff: builder.mutation({
        query: ({tariff_id}) => ({
            url: `/dsm/tariff-data/delete/${tariff_id}/`,
            method: "DELETE",
            }),
            invalidatesTags: ["tariff"],
            }), 
  }),
});

export const { 
//GET REQUESTS -------------------------------------------------------------
useGetPublicHolidayListQuery,
useGetTOUListQuery,
useGetTariffListQuery,
//POST REQUESTS
useAddPublicHolidayMutation,
useAddTOUMutation,
useAddTariffMutation,
//PATCH REQUESTS
useUpdateBulkPublicHolidayMutation,
useUpdateBulkTOUMutation,
useUpdateBulkTariffMutation,
//DELETE REQUESTS
useDeletePublicHolidayMutation,
useDeleteTOUMutation,
useDeleteTariffMutation
} = financialApiSlice;


