import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { concatPathWithParams } from "../../api/apiHelper";

const rawDataAdapter = createEntityAdapter();

const initialState = rawDataAdapter.getInitialState();

export const rawDataAPISlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMeterInfo: builder.query({
            query: (meterID) => `/meter/${meterID}/`,
            providesTags: ["meter_info"],
        }),
        getMeterRawData: builder.query({
            query: (request) =>
                concatPathWithParams(
                    `/meter/${request.meterID}/rawdata/`,
                    request.queryParams
                ),
            providesTags: ["meter_raw_data"],
        }),
    }),
});

export const { useGetMeterInfoQuery, useGetMeterRawDataQuery } =
    rawDataAPISlice;
