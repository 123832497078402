import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Chart from "react-apexcharts";

export default function MunicipalityGraphGraph({
  municipalityGraphData,
  isSuccessMunicipalityGraphData,
  isErrorMunicipalityGraphData,
  errorMessageMunicipalityGraphData,
  isLoadingMunicipalityGraphData,
  startDate,
  endDate,
}) {
  const handleDownloadCSV = () => {
    // 1Min Data
    let csv1MinContent = "Datetime, Baseline, Assessment\n";

    municipalityGraphData?.baseline_plot?.forEach((dataPoint, index) => {
      const dateObj = new Date(dataPoint.x);
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear().toString();

      csv1MinContent += `${year}-${month}-${day} ${hours}:${minutes},${dataPoint.y},${municipalityGraphData.assessment_plot[index].y}\n`;
    });

    if (csv1MinContent !== "") {
      const anchorEle = document.createElement("a");
      anchorEle.href = `data:text/csv;charset=utf-8,${encodeURI(csv1MinContent)}`;
      anchorEle.target = "_blank";
      anchorEle.download = `Municipality Demand M&V 1min-${startDate.format(
        "YYYY-MM-DD"
      )}to${endDate.format("YYYY-MM-DD")}.csv`;
      anchorEle.click();
    }

    // 30min Data
    let csv30MinContent = "Datetime, Baseline, Assessment\n";
    let baselineSum = 0;
    let assessmentSum = 0;
    let count = 0;
    let lastIntervalEnd = null;
  
    municipalityGraphData?.baseline_plot?.forEach((dataPoint, index) => {
      const dateObj = new Date(dataPoint.x);
      const minutes = dateObj.getMinutes();
      const isEndOfInterval = minutes === 0 || minutes === 30;
  

      baselineSum += dataPoint.y;
      assessmentSum += municipalityGraphData.assessment_plot[index].y;
      count++;
  
      if (isEndOfInterval) {

        const intervalEndDateObj = new Date(dataPoint.x);
        const intervalHours = intervalEndDateObj.getHours().toString().padStart(2, "0");
        const intervalMinutes = intervalEndDateObj.getMinutes().toString().padStart(2, "0");
        const day = intervalEndDateObj.getDate().toString().padStart(2, "0");
        const month = (intervalEndDateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = intervalEndDateObj.getFullYear().toString();

        csv30MinContent += `${year}-${month}-${day} ${intervalHours}:${intervalMinutes},${baselineSum},${assessmentSum}\n`;
  
        baselineSum = 0;
        assessmentSum = 0;
        count = 0;
      }
    });

    if (count > 0) {
      const lastDateObj = new Date(municipalityGraphData.baseline_plot[municipalityGraphData.baseline_plot.length - 1].x);
      const lastHours = lastDateObj.getHours().toString().padStart(2, "0");
      const lastMinutes = lastDateObj.getMinutes().toString().padStart(2, "0");
      const day = lastDateObj.getDate().toString().padStart(2, "0");
      const month = (lastDateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = lastDateObj.getFullYear().toString();
  
      csv30MinContent += `${year}-${month}-${day} ${lastHours}:${lastMinutes},${baselineSum},${assessmentSum}\n`;
    }
  
    if (csv30MinContent !== "") {
      const anchorEle1 = document.createElement("a");
      anchorEle1.href = `data:text/csv;charset=utf-8,${encodeURI(csv30MinContent)}`;
      anchorEle1.target = "_blank";
      anchorEle1.download = `Municipality Demand M&V 30min-${startDate.format(
        "YYYY-MM-DD"
      )}to${endDate.format("YYYY-MM-DD")}.csv`;
      anchorEle1.click();
    }
  };

  if (isLoadingMunicipalityGraphData) {
    return (
      <Card variant="outlined" style={{ marginTop: "1em" }}>
        <Grid
          container
          style={{
            maxWidth: "1700px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Grid item xs={12} align="right">
            <div>Loading....</div>
          </Grid>
        </Grid>
      </Card>
    );
  } else if (isErrorMunicipalityGraphData) {
    return (
      <Card variant="outlined" style={{ marginTop: "1em" }}>
        <Grid
          container
          style={{
            maxWidth: "1700px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Grid item xs={12} align="right">
            <div>
              Error: {errorMessageMunicipalityGraphData?.data?.error_message}
            </div>
          </Grid>
        </Grid>
      </Card>
    );
  } else if (isSuccessMunicipalityGraphData) {

    const options = {
      responsive: [
        {
          breakpoint: 600,
          options: {
            xaxis: {
              tickAmount: 8,
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            xaxis: {
              tickAmount: 12,
            },
          },
        },
      ],
      legend: {
        show: false
      },
      noData: {
        text: "NO DATA FOUND",
        align: "center",
        verticalAlign: "middle",
      },
      chart: {
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },

      stroke: {
        curve: "stepline",
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: "dd MMM HH:mm:ss",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        decimalsInFloat: 2,
      },
    };

    const series = [
      {
        name: "Assessment",
        data: municipalityGraphData?.assessment_plot,
        type: "line",
        color: "#FDB940",
      },
      {
        name: "Baseline",
        data: municipalityGraphData?.baseline_plot,
        type: "line",
        color: "#94ADFF",
      },
    ];

    return (
      <Card variant="outlined" style={{ marginTop: "1em" }}>
        <Grid
          container
          style={{
            maxWidth: "1700px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Grid item xs={12} align="right">
            <Button onClick={handleDownloadCSV}>Download CSV</Button>
          </Grid>
          <Grid item xs={12}>
            <Chart options={options} series={series} height={450} />
          </Grid>
        </Grid>
      </Card>
    );
  } else {
    return <></>;
  }
}
