import * as React from "react";
import Box from "@mui/material/Box";
import { ReactComponent as SvgFile } from "./logo.svg";
import { useState } from "react";
import { Nav } from "./Elements";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

import "@fontsource/roboto/400.css";
import { getlogo, getnavHeading } from "./navSlice";
import { useNavigate } from "react-router-dom";

export default function QENavbar() {
  const navName = useSelector(getnavHeading);
  const showLogo = useSelector(getlogo);
  return (
    <Box sx={{ mb: 5, background: "#FDB940" }}>
      <Nav>
        <br />
        <Grid container alignItems="center" spacing={0.5}>
          <Grid item xs={2} align="center">
            {showLogo ? <SvgFile /> : <Backbutton />}
          </Grid>
          <Grid item xs={8} align="center" sx={{ color: "#fff" }}>
            {navName}
          </Grid>
        </Grid>
      </Nav>
    </Box>
  );
}

function Backbutton() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return <ArrowBackIcon onClick={handleBack} sx={{ color: "white" }} />;
}
