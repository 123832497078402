import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
export default function RawDataRegisterToggle({register, setRegister}) {

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                maxWidth: "1700px",
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <ToggleButtonGroup
                color="primary"
                value={register}
                exclusive
                onChange={(e) => setRegister(e.target.value)}
                aria-label="Register Toggle"
            >
                <ToggleButton value="ActivePower">Active Power</ToggleButton>
                <ToggleButton value="TotalActiveEnergy">Total Active Energy</ToggleButton>
                <ToggleButton value="SignalQuality">Signal Quality</ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
}
