import Grid from "@mui/material/Grid";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function HeadingRawData({}) {
    const navigate = useNavigate();
    const { serial_number } = useParams();
    const handlePrevPage = () => {
        navigate(-1);
    };

    return (
        <Grid container>
            <Grid item xs={0.5}>
                <ArrowBackIcon
                    size="large"
                    sx={{ color: "#000", mb: 2, mt: 2, cursor: "pointer" }}
                    onClick={handlePrevPage}
                />
            </Grid>
            <Grid item xs={11.5} align="left">
                <Typography variant="h5" sx={{ mb: 2, mt: 1.5 }}>
                    Tjommie SN : {serial_number}
                </Typography>
            </Grid>
        </Grid>
    );
}
