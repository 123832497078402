
import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  useGetFieldAreaListQuery,
  useGetDashboardInstallerQuery,
  useGetTargetInstallsQuery,
  useGetStockOnHandQuery,
} from "../api/apiSlice";
import { FiberManualRecord } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import Chart from "react-apexcharts";
import dayjs from 'dayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from 'react'; // Import useState
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';


export default function Installs() {
  const [countStockField, setCountStockField] = React.useState(0);

  const { data: areas, isSuccess: isGetAreasSuccess } =
    useGetFieldAreaListQuery();

  React.useEffect(() => {
    if (isGetAreasSuccess && areas.field_areas.length > 0) {
      const totalStockCount = areas.field_areas.reduce(
        (acc, item) => acc + item.stock_item_count,
        0
      );
      setCountStockField(totalStockCount);
    }
  }, [isGetAreasSuccess, areas, setCountStockField]);

  
  if (isGetAreasSuccess) {
    return (
      <div>
\
        <InstallerCards countStock={countStockField} />
       
      </div>
    );
  } else {
    return (
      <div>
      </div>
    );
  }
}

function InstallerCards({ countStock }) {
    const [selectedDate, setSelectedDate] = useState(dayjs());
  
    const handleDateChange = (newDate) => {
      setSelectedDate(newDate);
    };
  
      const formattedDate = selectedDate.format("YYYY-MM-DD");
  
    const {
      data: installer_details,
      isSuccess: isInstallerSuccess,
      error: errorMessage,
    } = useGetDashboardInstallerQuery(formattedDate);
    const { data: target, isSuccess: isTargetSuccess } =
      useGetTargetInstallsQuery();
    const { data: stock_on_hand, isSuccess: isStockOnHandSuccess } =
      useGetStockOnHandQuery();
    const renderCircle = (color) => (
      <div
        style={{
          width: "30px",
          height: "4px",
          borderRadius: "20%",
          backgroundColor: color,
          // marginRight: "0px",
        }}
      />
    );
    if (isInstallerSuccess && isTargetSuccess && isStockOnHandSuccess) {
      const installed = installer_details.installed_devices;
      const target_installs = 984;
      const chartSeries = [countStock, target_installs - countStock];
      const percentage = (countStock / target_installs) * 100;
      const chartOptions = {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "18px",
                  color: "#c",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  color: "#000",
                  offsetY: 5,
                  fontSize: "14px",
                  formatter: function (val) {
                    return "Target Installs";
                  },
                },
                total: {
                  show: true,
                  label: `${countStock}/${target_installs}`,
                  color: "#373d3f",
                  fontSize: "24px",
                  formatter: function () {
                    return "Target Installs";
                  },
                },
              },
            },
          },
        },
        labels: ["Installed", "Target"],
        colors: ["#FDB940", "#DEDEDE"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };
      return (
        <Grid container alignItems="center" sx={{mt:15}}>
          
          <Grid item lg={2} />
          <Grid item xs={12} lg={2} align="center" >
            <Paper
              sx={{
                width: "90%",
                height: 252,
                p: 2,
                backgroundColor: "#F8F8F8",
                borderRadius: 4,
              }}
              elevation={0}
            >
              <Typography
                align="right"
                variant="h6"
                sx={{
                  mt: -1,
                  mb: -1,
                  color: "#000",
                }}
              >
                {percentage.toFixed(2)}%
              </Typography>
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="donut"
                width="100%"
                height="95%"
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: -3,
                }}
              >
                <FiberManualRecord style={{ color: green[500] }} />
                <Typography
                  align="center"
                  variant="h6"
                  sx={{
                    color: "#000",
                  }}
                >
                  {installer_details.connected} Online Devices
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6.5} align="center">
            <Paper
              sx={{
                width: "90%",
                height: 252,
                p: 2,
                backgroundColor: "#F8F8F8",
                borderRadius: 4,
              }}
              elevation={0}
            >
              <Grid container sx={{ p: 1 }}>
               
                <Grid item xs={6} align="left">
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 24,
                      mb: 2,
                      mt: 1,
                    }}
                  >
                    Installs
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date Range"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography
                    sx={{
                      display: "inline",
                      alignItems: "center",
                      fontSize: 32,
                      mr: 1,
                    }}
                  >
                    {installed}
                  </Typography>
                  <Typography
                    sx={{ display: "inline", alignItems: "center", fontSize: 14 }}
                  >
                    Installed today
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress
                    variant="determinate"
                    value={(installed / (stock_on_hand.count + installed)) * 100}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      mt: 2,
                      backgroundColor: "#DEDEDE",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#FDB940",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={1.5} md={1} sx={{ mt: 5 }} align="left">
                  {renderCircle("#FDB940")}
                </Grid>
                <Grid item xs={4} sx={{ mt: 4 }} align="left">
                  <Typography
                    sx={{
                      display: "inline",
                      alignItems: "left",
                      fontSize: 16,
                      color: "#7B7B7B",
                    }}
                  >
                    Devices installed
                  </Typography>
                </Grid>
                <Grid item xs={0.5} md={0} sx={{ mt: 4 }} align="left" />
                <Grid item xs={1.5} md={1} sx={{ mt: 5 }} align="left">
                  {renderCircle("#DEDEDE")}
                </Grid>
                <Grid item xs={4} sx={{ mt: 4 }} align="left">
                  <Typography
                    sx={{
                      display: "inline",
                      alignItems: "left",
                      fontSize: 16,
                      color: "#7B7B7B",
                    }}
                  >
                    Dispatched Stock
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1.5} />
                <Grid item md={0.5} />
                <Grid item xs={1.5} md={1} align="right">
                  <Typography
                    sx={{
                      display: "inline",
                      alignItems: "left",
                      fontSize: 16,
                      color: "#11263C",
                    }}
                  >
                    {installed}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={5} md={5} align="left" />
                <Grid item xs={1.5} md={1} align="left">
                  <Typography
                    sx={{
                      display: "inline",
                      alignItems: "left",
                      fontSize: 16,
                      color: "#11263C",
                    }}
                  >
                    {stock_on_hand.count}
                  </Typography>{" "}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item lg={1.5} />
        </Grid>
      );
    } else {
      return null;
    }
  }