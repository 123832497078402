import React from "react";
import { Card, Grid, Typography } from "@mui/material";

export default function MunicipalityTable({
  municipalityTableData,
  isLoadingMunicipalityTableData,
  isSuccessMunicipalityTableData,
  isErrorMunicipalityTableData,
  errorMessageMunicipalityTableData,
  startDate,
  endDate,
}) {
  if (isSuccessMunicipalityTableData) {
    return (
      <Grid container spacing={0.5} direction="row" alignItems="stretch">
        <Grid item xs={2}>
          <Card
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              height: "80%",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Average Power (MVA)
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={10}>
          <Card
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              height: "80%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Normalized Baseline:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Assessment:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Saving:
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography color="#999999">Saturdays, Morning</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.saturday_morning_ls
                      .baseline_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.saturday_morning_ls
                      .assessment_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
              <Typography>
                  {Math.max(
                    municipalityTableData.saturday_morning_ls
                      .baseline_demand_total /
                      1000000 -
                      municipalityTableData.saturday_morning_ls
                        .assessment_demand_total /
                        1000000,
                    0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="#999999">Saturdays, Evening</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.saturday_evening_ls
                      .baseline_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.saturday_evening_ls
                      .assessment_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
              <Typography>
                  {Math.max(
                    municipalityTableData.saturday_evening_ls
                      .baseline_demand_total /
                      1000000 -
                      municipalityTableData.saturday_evening_ls
                        .assessment_demand_total /
                        1000000,
                    0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="#999999">Weekdays, Morning</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.weekday_morning_ls
                      .baseline_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.weekday_morning_ls
                      .assessment_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
              <Typography>
                  {Math.max(
                    municipalityTableData.weekday_morning_ls
                      .baseline_demand_total /
                      1000000 -
                      municipalityTableData.weekday_morning_ls
                        .assessment_demand_total /
                        1000000,
                    0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="#999999">Weekdays, Evening</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.weekday_evening_ls
                      .baseline_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {(
                    municipalityTableData.weekday_evening_ls
                      .assessment_demand_total / 1000000 || 0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {Math.max(
                    municipalityTableData.weekday_evening_ls
                      .baseline_demand_total /
                      1000000 -
                      municipalityTableData.weekday_evening_ls
                        .assessment_demand_total /
                        1000000,
                    0
                  ).toFixed(2)}{" "}
                  MVA
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
}
