import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useState } from "react";
export default function DateRangePickerRawData({
    setEndDate,
    setStartDate,
    meterInfo
}) {
    const { serial_number} = useParams();
    const [startDatePlaceHolder, setStartDatePlaceHolder] = useState(dayjs().subtract(1, "day"));
    const [endDatePlaceHolder, setEndDatePlaceHolder] = useState(dayjs());

    const handleStartDateChange = (newDate) => {
        setStartDatePlaceHolder(newDate);
    };

    const handleEndDateChange = (newDate) => {
        setEndDatePlaceHolder(newDate);
    };

    const handleDateChangeConfirm = () => {
        setStartDate(startDatePlaceHolder);
        setEndDate(endDatePlaceHolder);
    };

    return (
        <Grid
            container
            gap={1}
            style={{
                marginTop: "1em",
                padding: "2em",
                maxWidth: "1700px",
                marginLeft: "auto",
                marginRight: "auto",
            }}
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <Grid container gap={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Start Date"
                            value={startDatePlaceHolder}
                            onChange={handleStartDateChange}
                        />
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="End Date"
                            value={endDatePlaceHolder}
                            onChange={handleEndDateChange}
                        />
                    </LocalizationProvider>
                    <Button onClick={handleDateChangeConfirm}>Confirm Dates</Button>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container gap={3}>
                    <Grid item>
                        <Typography variant="h6">Serial Number - {serial_number}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">UUID - {meterInfo?.uuid}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
