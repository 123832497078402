import * as React from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setlogo, setnavHeading } from "./Nav/navSlice";

const dummyData = [
  { id: 1, address: "123 Main St, City A" },
  { id: 2, address: "456 Elm St, City B" },
  { id: 3, address: "789 Oak St, City C" },
];

export default function PendingInstalls() {
  const dispatch = useDispatch();
  dispatch(setnavHeading("Pending installs"));
  dispatch(setlogo(false));
  return (
    <>
      <Helmet>
        <style>{"body { background: #FFF1D7; }"}</style>
      </Helmet>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ mt: 10 }}
      >

        <InstallsList data={dummyData} />
      </Grid>
    </>
  );
}

function InstallsList({ data }) {
  return data.map((item) => (
    <>
      <Grid item xs={0.25} key={item.id} />
      <Grid item xs={11.5} key={item.id}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            p: 2,
            mb: 2,
            cursor: "pointer",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography sx={{ color: "black" }}>{item.address}</Typography>
          <IconButton>
            <NotificationsActiveIcon sx={{ color: "grey" }} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={0.25} />
    </>
  ));
}
